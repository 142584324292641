:root {
    --fs: 16px;
    --lh: 24px;
    --bw: 2px;
    --base03: #002b36;
    --base02: #073642;
    --base01: #586e75;
    --base00: #657b83;
    --base0: #839496;
    --base1: #93a1a1;
    --base2: #eee8d5;
    --base3: #fdf6e3;
    --yellow: #b58900;
    --orange: #cb4b16;
    --red: #dc322f;
    --magenta: #d33682;
    --violet: #6c71c4;
    --blue: #268bd2;
    --cyan: #2aa198;
    --green: #859900;
}

html * {
    margin: 0px;
    padding: 0px;
    line-height: calc(var(--lh) * 1.2);
    font-size: var(--fs);
    border-width: var(--bw);
}

header,
article,
footer {
    max-width: calc(var(--fs) * 80);
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(var(--lh));
    padding-right: calc(var(--fs) * 2);
    padding-left: calc(var(--fs) * 2);
    padding-bottom: calc(var(--lh));
}

header {
    border: none;
    border-bottom: 2px dashed var(--base0);
}

p,
pre,
nav,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: var(--lh);
}

.site-header  {
	line-height: calc(var(--lh) * 1.5);
	font-size: calc(var(--fs) * 1.5);
}

.site-header * {
	line-height: calc(var(--lh) * 1.5);
	font-size: calc(var(--fs) * 1.5);
}

h1 {
    line-height: calc(var(--lh) * 1.5);
    font-size: calc(var(--fs) * 1.5);
}

h1 * {
    line-height: calc(var(--lh) * 1.5);
    font-size: calc(var(--fs) * 1.5);
}

h1 a {
    margin-right: var(--fs);
}

h3 {
    text-decoration: underline;
}

h4 {
    text-decoration: wavy underline;
}

h5 {
    text-decoration: underline dotted;
}

h6 {
    margin-left: calc(var(--fs));
    text-decoration: wavy underline dotted;
}

pre {
    padding-left: calc(var(--fs) * 2);
}

pre * {
    line-height: calc(var(--fs) * 1.2);
}

code {
    background-color: var(--base0);
    color: var(--base3);
    font-weight: bold;
    padding: calc(var(--bw) * 2);
}

input,
select,
button {
    height: calc(var(--lh) + 2 * var(--bw));
    margin-left: calc(var(--fs) * 0.5);
    margin-right: calc(var(--fs) * 0.5);
    padding-left: calc(var(--fs) * 0.5);
    padding-right: calc(var(--fs) * 0.5);
    vertical-align: middle;
}

.i-stxt {
    width: calc(var(--fs) * (5 + 1));
}

.i-txt {
    width: calc(var(--fs) * (5 * 2 + 1));
}

.i-ltxt {
    width: calc(var(--fs) * (5 * 4 + 1));
}

.i-btn {
    padding-left: var(--lh);
    padding-right: var(--lh);
}

.icon {
    display: inline-block;
    vertical-align: middle;
    height: var(--lh);
}

nav,
footer {
    a {
        margin-right: var(--lh);
        font-weight: bold;
        text-decoration: none
    }

    a:hover {
        text-decoration: underline;
    }

    div {
        float: right;
        display: block;
    }

    ul li {
        display: block;
    }
}

footer {
    * {
        line-height: calc(var(--lh) * 1.5)
    }
}

article img {
    width: 512px;
    margin-left: auto;
    margin-right: auto;
}

article * li {
	margin-left: calc(var(--fs) * 2) ;
}

article  {
    ol,
    ul {
    margin-bottom: var(--lh);
    }	
}

article a,
article span {
    margin: 0 calc(var(--fs) / 2) 0 calc(var(--fs) / 2);
}

.a-c {
    text-align: center;
}

html,
.light {
    background-color: var(--base2);
    color: var(--base00);

    * {
        color: var(--base00);
        border-color: var(--base00);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .title {
        color: var(--violet);
        border-color: var(--base0);
    }

    a,
    a:active,
    a:hover {
        color: var(--blue);
    }

    a:visited {
        color: var(--cyan);
    }

    code , pre {
        background-color: var(--base0);
        color: var(--base3);
    }

    header,
    article {
        background-color: var(--base3);
    }

    footer {
        background-color: var(--base03);
        color: var(--base0);

    }

    footer * {
        color: var(--base0);
    }

}

@media screen and (min-width: 400px) {

    nav,
    footer {
        ul li {
            display: inline-flex;
        }
    }
}